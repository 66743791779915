import { ReactNode } from "react";
import { useTheme, TextAlign, ColorPreset } from "@gocardless/flux-react";

interface InstalmentsListSummaryCellProps {
  children?: ReactNode;
  alignRight?: boolean;
  topBorder?: boolean;
  paddingBottom?: boolean;
  noLeftPadding?: boolean;
  noRightPadding?: boolean;
  isMobile: boolean;
}

export const InstalmentsListSummaryCell = ({
  children,
  alignRight,
  topBorder,
  paddingBottom,
  noLeftPadding,
  noRightPadding,
  isMobile,
}: InstalmentsListSummaryCellProps) => {
  const { theme } = useTheme();
  const mobilePadding = `${theme.spacing(0.5)} ${
    noRightPadding ? "0" : theme.spacing(0.5)
  } ${paddingBottom ? theme.spacing(1) : "0"} ${
    noLeftPadding ? "0" : theme.spacing(0.5)
  }`;
  const desktopPadding = `${theme.spacing(1)} ${
    noRightPadding ? "0" : theme.spacing(1.5)
  } ${paddingBottom ? theme.spacing(1) : "0"} ${
    noLeftPadding ? "0" : theme.spacing(1.5)
  }`;
  return (
    <td
      css={{
        padding: isMobile ? mobilePadding : desktopPadding,
        textAlign: alignRight ? TextAlign.Right : undefined,
        borderTop: topBorder
          ? `1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`
          : undefined,
      }}
    >
      {children}
    </td>
  );
};
