import {
  AvailableDebitSchemeEnum,
  BillingRequestFlowResource,
  BillingRequestResource,
  ConsentType,
  InstalmentTemplateResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import {
  isBankRecurringFlow,
  isDualFlow,
  isMandateOnlyFlow,
  leadBillingRequestScheme,
} from "src/common/utils";
import { getPaymentVariant, PaymentVariant } from "src/common/payments";
import { ExpandedStates } from "src/common/states";
import { ColorPreset, Separator } from "@gocardless/flux-react";
import { Scheme } from "src/common/scheme";

import { Routes } from "../Router";

import PlanDescription from "./PlanDescription";
import InstalmentTemplateDescription from "./InstalmentTemplateDescription";
import PaymentAgreementDescription from "./PaymentAgreementDescription";
import { MandateDescription } from "./MandateDescription";
import { PaymentDescription } from "./PaymentDescription";

export interface BillingRequestDescriptionProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  paylink: PaylinkResource | undefined;
  mandateRequestExtendedConfirmation?: boolean;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  page?: Routes;
}

// This should render a description that summarises the contents in the Billing
// Request. If we're setting up a mandate, that should be made clear — likewise
// with any payment requests, or other resources that we support against Billing
// Requests in the future.
const BillingRequestDescription = ({
  billingRequest,
  billingRequestFlow,
  mandateRequestExtendedConfirmation,
  plan,
  instalmentTemplate,
  paylink,
  page,
}: BillingRequestDescriptionProps) => {
  const paymentVariant = getPaymentVariant(billingRequest, paylink, plan);

  const showPlanDescription =
    plan && paymentVariant !== PaymentVariant.VariableFirstPaymentWithPlan;
  const instantBankPayCurrency = billingRequest?.payment_request?.currency;
  const instantBankPayAmount = parseInt(
    billingRequest?.payment_request?.amount?.toString() ?? "0"
  );
  const directDebitCurrency = paylink?.currency;
  const directDebitAmount = parseInt(paylink?.amount?.toString() ?? "0");
  const description =
    billingRequest.payment_request?.description ?? paylink?.name;
  const leadScheme = leadBillingRequestScheme(billingRequest);

  if (instalmentTemplate) {
    return (
      <InstalmentTemplateDescription
        instalmentTemplate={instalmentTemplate}
        billingRequest={billingRequest}
      />
    );
  }
  if (showPlanDescription) {
    return <PlanDescription plan={plan} billingRequest={billingRequest} />;
  }
  if (
    isBankRecurringFlow(billingRequest) &&
    (isMandateOnlyFlow(billingRequest) || isDualFlow(billingRequest))
  ) {
    return (
      <PaymentAgreementDescription
        defaultExpanded={
          page === Routes.CollectBankAccount
            ? ExpandedStates.Collapsed
            : ExpandedStates.Default
        }
        schemeLogo={translateForScheme({
          scheme: leadScheme as AvailableDebitSchemeEnum,
          translationKey: "billing-request-description-info-scheme-logo",
          params: {},
        })}
        billingRequest={billingRequest}
      />
    );
  }

  const isAchOrPad = [
    AvailableDebitSchemeEnum.Ach,
    AvailableDebitSchemeEnum.Pad,
  ].includes(
    billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum
  );

  const showDualFlowMandateDescription =
    !plan &&
    (!isAchOrPad ||
      billingRequest.subscription_request ||
      billingRequest.instalment_schedule_request);

  const consentType = billingRequest.mandate_request?.consent_type
    ? (billingRequest.mandate_request?.consent_type as ConsentType)
    : undefined;

  switch (paymentVariant) {
    case PaymentVariant.VariableFirstPaymentWithPlan:
      return (
        <PaymentDescription
          consentType={consentType}
          scheme={billingRequest.payment_request?.scheme as Scheme}
          isAmountFlexible={billingRequest.payment_request?.flexible_amount}
          paymentVariant={paymentVariant}
          currency={instantBankPayCurrency}
          amountInPence={instantBankPayAmount}
          description={description}
          page={page}
          schemeLogo={translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
        />
      );
    case PaymentVariant.InstantBankPay:
      return (
        <PaymentDescription
          scheme={billingRequest.payment_request?.scheme as Scheme}
          isAmountFlexible={billingRequest.payment_request?.flexible_amount}
          paymentVariant={paymentVariant}
          currency={instantBankPayCurrency}
          amountInPence={instantBankPayAmount}
          description={description}
          page={page}
        />
      );
    case PaymentVariant.DualFlow:
    case PaymentVariant.VariableRecurringPaymentsWithFirstPayment:
      return (
        <>
          <PaymentDescription
            consentType={consentType}
            scheme={billingRequest.payment_request?.scheme as Scheme}
            isAmountFlexible={billingRequest.payment_request?.flexible_amount}
            paymentVariant={paymentVariant}
            currency={instantBankPayCurrency}
            amountInPence={instantBankPayAmount}
            description={description}
            page={page}
            showBankIcon={!showDualFlowMandateDescription}
            schemeLogo={translateForScheme({
              scheme: billingRequest?.mandate_request
                ?.scheme as AvailableDebitSchemeEnum,
              translationKey: "billing-request-description-info-scheme-logo",
              params: {},
            })}
          />
          {showDualFlowMandateDescription && (
            <>
              <Separator color={ColorPreset.BorderOnLight_04} />
              <MandateDescription
                paymentVariant={paymentVariant}
                billingRequest={billingRequest}
                billingRequestFlow={billingRequestFlow}
                extendedConfirmation={mandateRequestExtendedConfirmation}
                page={page}
              />
            </>
          )}
        </>
      );
    case PaymentVariant.DirectDebitOneOff:
      return (
        <PaymentDescription
          scheme={billingRequest.payment_request?.scheme as Scheme}
          paymentVariant={paymentVariant}
          currency={directDebitCurrency}
          amountInPence={directDebitAmount}
          schemeLogo={translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
          description={description}
        />
      );
    case PaymentVariant.DirectDebitRestrictedMandate:
      return (
        <>
          <PaymentDescription
            scheme={billingRequest.payment_request?.scheme as Scheme}
            paymentVariant={paymentVariant}
            currency={directDebitCurrency}
            amountInPence={directDebitAmount}
            description={description}
          />
          <Separator color={ColorPreset.BorderOnLight_04} />
          <MandateDescription
            paymentVariant={paymentVariant}
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            extendedConfirmation={mandateRequestExtendedConfirmation}
            page={page}
          />
        </>
      );
    case PaymentVariant.DirectDebitMandate:
    case PaymentVariant.VerifiedMandate:
      return (
        <MandateDescription
          paymentVariant={paymentVariant}
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          extendedConfirmation={mandateRequestExtendedConfirmation}
          page={page}
        />
      );

    default:
      return null;
  }
};

export default BillingRequestDescription;
