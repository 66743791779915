import {
  XYGrid,
  P,
  FontWeight,
  AlignItems,
  JustifyContent,
  ColorPreset,
  Box,
  ButtonVariant,
  ButtonSize,
  Glyph,
  TypePreset,
  H2,
} from "@gocardless/flux-react";
import { ReactElement, useContext } from "react";
import { PaymentVariant } from "src/common/payments";
import { getBrandColorFor, BrandedComponentType } from "src/common/utils";
import Amount from "src/legacy-ui/amount";
import { GlobalState } from "src/state";
import { Trans } from "@lingui/macro";
import {
  AvailableDebitSchemeEnum,
  ConsentType,
} from "@gocardless/api/dashboard/types";
import { Scheme } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";

import BrandedButton from "../../BrandedComponents/BrandedButton";
import { Routes } from "../../Router";
import { ConsentTypeTag } from "../../components/ConsentTypeTag";

interface PaymentDescriptionProps {
  paymentVariant: PaymentVariant | null;
  currency: string | undefined;
  amountInPence: number | undefined;
  description: string | null | undefined;
  isAmountFlexible?: boolean;
  scheme: Scheme;
  schemeLogo?: ReactElement | null;
  page?: Routes;
  consentType?: ConsentType | undefined;
  showBankIcon?: boolean;
}

const getBottomText = (
  scheme: Scheme,
  showBankIcon: boolean,
  consentType?: ConsentType,
  page?: Routes
) => {
  const isAchOrPad = [
    AvailableDebitSchemeEnum.Ach,
    AvailableDebitSchemeEnum.Pad,
  ].includes(scheme);
  if (!isAchOrPad) {
    return undefined;
  }
  const variableConsentTypes = [
    ConsentType.Standing,
    ConsentType.Sporadic,
    ConsentType.Single,
  ];
  const isBankConfirmPage = page === Routes.BankConfirm;

  if (
    !consentType ||
    !variableConsentTypes.includes(consentType) ||
    (scheme === Scheme.Pad && !isBankConfirmPage)
  ) {
    return (
      <Box
        layout="flex"
        alignItems={AlignItems.End}
        justifyContent={JustifyContent.SpaceBetween}
      >
        <P size={1} color={ColorPreset.TextOnLight_02}>
          <Trans>
            Payment will be collected as soon as possible, usually within a few
            days
          </Trans>
        </P>
        {showBankIcon && (
          <Box layout="flex" spaceBefore={[1, null, 2]}>
            {translateForScheme({
              scheme,
              translationKey: "billing-request-description-info-scheme-logo",
              params: {},
            })}
          </Box>
        )}
      </Box>
    );
  }
  const hasStandingDebit = [
    ConsentType.Standing,
    ConsentType.Sporadic,
  ].includes(consentType);

  if (isBankConfirmPage && hasStandingDebit) {
    const { toolTipContent } = getDisplayedConsentInfo(scheme, consentType);
    return (
      <P spaceAbove={1} size={1} color={ColorPreset.TextOnLight_02}>
        {toolTipContent}
      </P>
    );
  }

  if (isBankConfirmPage && scheme === Scheme.Pad) {
    {
      return (
        <>
          <P size={1} color={ColorPreset.TextOnLight_02}>
            <Trans>
              Payment will be collected as soon as possible, usually within a
              few days
            </Trans>
          </P>
          <P
            size={1}
            color={ColorPreset.TextOnLight_02}
            weight={FontWeight.Normal}
          >
            <Trans>
              This Pre-Authorized Debit (PAD) Agreement will no longer be valid
              once the payment has been fulfilled. Any subsequent PAD(s) require
              a newly Authorized Payor&apos;s PAD Agreement.
            </Trans>
          </P>
        </>
      );
    }
  }

  return undefined;
};

export const PaymentDescription = ({
  consentType,
  paymentVariant,
  currency = "GBP",
  amountInPence = 0,
  schemeLogo,
  description,
  isAmountFlexible,
  page,
  scheme,
  showBankIcon = false,
}: PaymentDescriptionProps) => {
  const { push, payerTheme, setIsChangeAmountLinkClicked } =
    useContext(GlobalState);
  const { consentTypeText, toolTipContent } = getDisplayedConsentInfo(
    scheme,
    ConsentType.OneOff
  );
  const showChangeAmountButton =
    isAmountFlexible && page !== Routes.CollectAmount;

  const displayedConsentType = consentTypeText || (
    <Trans id="billing-request-description.payment-description.one-off-payment">
      One-off payment
    </Trans>
  );

  const bottomSectionContent = getBottomText(
    scheme,
    showBankIcon,
    consentType,
    page
  );

  const showSchemeLogo =
    (schemeLogo && !bottomSectionContent && showBankIcon) ||
    (bottomSectionContent && consentType === ConsentType.Standing);

  return (
    <XYGrid rowGap={0.5}>
      <H2 preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
        {description}
      </H2>
      <XYGrid
        templateColumns={
          paymentVariant === PaymentVariant.DirectDebitOneOff
            ? "auto auto auto 1fr"
            : "auto auto 1fr"
        }
        columnGap={1}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Start}
      >
        <Amount currency={currency} amountInPence={amountInPence} />
        <ConsentTypeTag
          displayedConsentType={displayedConsentType}
          toolTipContent={toolTipContent}
        />
        {showChangeAmountButton && (
          <Box css={{ justifySelf: "end" }}>
            <BrandedButton
              textColor={getBrandColorFor(
                BrandedComponentType.Link,
                payerTheme
              )}
              variant={ButtonVariant.Inline}
              onClick={() => {
                setIsChangeAmountLinkClicked(true);
                push(Routes.CollectAmount, {
                  origin: "BillingRequestDescription",
                  reason: "clicked change amount link",
                });
              }}
              size={ButtonSize.Sm}
              leftIcon={Glyph.Edit}
              id="changeCustomer"
            >
              <Trans id="confirm-details-page.form.change-button">Change</Trans>
            </BrandedButton>
          </Box>
        )}
        {showSchemeLogo && (
          <Box layout="flex" css={{ justifySelf: "end", alignSelf: "end" }}>
            {schemeLogo}
          </Box>
        )}
      </XYGrid>
      {bottomSectionContent && bottomSectionContent}
    </XYGrid>
  );
};
