import { useContext } from "react";
import { Box } from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";
import { Image } from "src/components/shared/Image";
import { Logger } from "src/common/logger";
import { EventName, postEvent } from "src/dropin/events";
import { isMobile } from "react-device-detect";

const exitButtonMargin = isMobile ? "24px" : "32px";

// Only to be rendered when we're running in the Dropin, this button triggers a
// request to the parent iframe to terminate the Billing Request Flow.
const ExitButton = ({ onExitOverride }: { onExitOverride?: () => void }) => {
  const { runtimeMode, dropinOrigin, billingRequest, billingRequestFlow } =
    useContext(GlobalState);

  const log = Logger("ExitButton");

  // The exit button only works for the Dropin, when it makes sense that you can
  // exit the modal. When you're in hosted, the exit button is quiting your
  // browser window.
  if (runtimeMode !== RuntimeMode.Dropin) {
    return null;
  }

  // We, the BRF app, don't handle exit- we post a message to our parent iframe,
  // asking to be exited.
  //
  // This allows the parent app to decide how to handle our clean-up, and
  // provides control back to the integrator.
  const onExit =
    onExitOverride ||
    (() => {
      log({
        message: "clicked exit button, sending message",
        event_name: EventName.EXIT,
      });
      postEvent({
        target: window.parent,
        targetOrigin: dropinOrigin,
        event: {
          name: EventName.EXIT,
          payload: {
            billingRequest,
            billingRequestFlow,
          },
        },
      });
    });

  return (
    <Box
      css={{
        float: "right",
        cursor: "pointer",
        marginTop: exitButtonMargin,
        marginRight: exitButtonMargin,
      }}
      onClick={onExit}
    >
      <Image
        name="Exit"
        alt="exit"
        css={{ height: "14px", width: "14px" }}
        viewBox="16px 16px"
      />
    </Box>
  );
};

export default ExitButton;
