import { isMobile } from "react-device-detect";
import { CSSRulesFunction } from "@gocardless/flux-react";

const paddingValues = [
  [8, 8, 8, 8], // mobile
  [24, 0, 24, 0], // desktop
];

const animationDuration = "300ms";

export const boxButtonStyle: CSSRulesFunction<{
  hoverBackgroundColor?: string;
}> = (theme, { hoverBackgroundColor }) => [
  theme.responsive(paddingValues, ([top, right, bottom, left]) => ({
    padding: `${top}px ${right}px ${bottom}px ${left}px`,
  })),
  {
    margin: "0 auto",
    height: ["100%", "auto"],
    borderRadius: "8px",
    flexGrow: 1,
    width: "100%",
    display: "inline",
    "& span": {
      textDecoration: "none",
    },
    "&:hover": {
      backgroundColor: isMobile ? hoverBackgroundColor : "transparent",
      borderRadius: theme.radius(0.5),
      textDecoration: "none",
    },
    transition: `background ${animationDuration} linear`,
    "& svg": {
      opacity: Number(isMobile),
      transition: `opacity ${animationDuration} linear`,
    },
    ":hover": {
      "& svg": {
        opacity: 1,
        transition: `opacity ${animationDuration} linear`,
      },
    },
  },
];
