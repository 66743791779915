import {
  AlignItems,
  Box,
  FontWeight,
  P,
  Text,
  XYGrid,
  Space,
  ColorPreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import Amount from "src/legacy-ui/amount";
import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";

import { TranslatedPaymentsCount } from "../../components/TranslatedPaymentsCount";
import { ConsentTypeTag } from "../../components/ConsentTypeTag";

export interface PlanDescriptionProps {
  plan: PlanResource;
  billingRequest: BillingRequestResource;
}

const PlanDescription = ({ plan, billingRequest }: PlanDescriptionProps) => {
  const paymentsTotal = plan?.count || undefined;

  return (
    <Box data-testid="plan-description">
      <XYGrid rowGap={0.25}>
        <P size={3} weight={FontWeight.SemiBold}>
          {plan?.name}
        </P>
        <Box layout="flex" alignItems={AlignItems.Baseline}>
          <Amount
            currency={plan?.currency || "GBP"}
            amountInPence={parseInt(plan?.amount?.toString() || "0")}
          />
          <Space h={1} layout="inline" />

          <ConsentTypeTag
            displayedConsentType={
              billingRequest.payment_request ? (
                <Trans id="billing-request-description.plan-description.to-pay-now">
                  To pay now
                </Trans>
              ) : (
                <Trans id="billing-request-description.plan-description.recurring-payment">
                  Recurring payment
                </Trans>
              )
            }
          />
        </Box>
        <Box layout="flex" alignItems={AlignItems.FlexEnd}>
          <Box flexGrow={1}>
            <P size={3} weight={FontWeight.SemiBold}>
              {billingRequest.payment_request && (
                <>
                  <Space v={0.5} />
                  {translateForScheme({
                    scheme: billingRequest?.mandate_request
                      ?.scheme as AvailableDebitSchemeEnum,
                    translationKey:
                      "billing-request-description-dual-flow-mandate-heading",
                  })}
                  <Space v={0.25} />
                </>
              )}
            </P>
            <P
              size={1}
              color={ColorPreset.TextOnLight_03}
              weight={FontWeight.Normal}
            >
              {billingRequest.payment_request && (
                <Text weight={FontWeight.SemiBold}>
                  <Amount
                    currency={plan?.currency || "GBP"}
                    amountInPence={parseInt(plan?.amount?.toString() || "0")}
                    simple
                  />
                  <Space h={0.25} layout="inline" />
                </Text>
              )}
              {getTranslatedPaymentPeriodicityMessage(plan)}
            </P>
            {paymentsTotal && (
              <P
                size={1}
                color={ColorPreset.TextOnLight_03}
                weight={FontWeight.Normal}
              >
                <Space v={0.25} />
                <TranslatedPaymentsCount paymentsCount={paymentsTotal ?? 0} />
              </P>
            )}
          </Box>
          {translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            translationKey: "collect-bank-account-page.bank-details-panel.logo",
            params: {},
          })}
        </Box>
      </XYGrid>
    </Box>
  );
};

export default PlanDescription;
