import { ConsentType } from "@gocardless/api/dashboard/types";
import {
  ColorProp,
  FontWeight,
  ResponsiveValue,
  SpaceScale,
  Text,
  TypeScale,
  XYGrid,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactNode } from "react";
import { Scheme, SchemesWithConsentParameters } from "src/common/scheme";

type BaseDisplayedConsentInformationType = {
  [key in ConsentType]: {
    toolTipContent: ReactNode;
    defaultSuccessHeading: ReactNode;
    consentTypeText: ReactNode;
    consentTypeContentText: ReactNode;
    consentTypeSuccessText: ReactNode;
    instalmentConsentTypeText?: ReactNode;
    defaultDescriptionText?: ReactNode;
    legalDisclaimer?: (props?: {
      space?: ResponsiveValue<SpaceScale>;
      textSize?: ResponsiveValue<TypeScale>;
      color?: ResponsiveValue<ColorProp>;
      isConfirmationPage?: boolean;
    }) => ReactNode;
  };
};

type DisplayedConsentInformationType = {
  [key in SchemesWithConsentParameters]: BaseDisplayedConsentInformationType;
};
const baseDisplayedConsentInformation: BaseDisplayedConsentInformationType = {
  [ConsentType.OneOff]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully set up a one-time ACH Debit Authorization
      </Trans>
    ),
    consentTypeContentText: <Trans>one-time ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>One-time payment</Trans>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,
    toolTipContent: (
      <Trans>
        A <Text weight={FontWeight.Bold}>one-time payment</Text> will be debited
        from your account.
      </Trans>
    ),
  },
  [ConsentType.Recurring]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully set up a recurring ACH Debit Authorization
      </Trans>
    ),
    consentTypeContentText: <Trans>recurring ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>Recurring payment</Trans>,
    instalmentConsentTypeText: <Trans>Installment plan total</Trans>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,

    toolTipContent: (
      <Trans>
        A <Text weight={FontWeight.Bold}>recurring payment</Text> will be
        debited from your account at regular intervals, without any additional
        action needed from you.
      </Trans>
    ),
  },
  [ConsentType.Single]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully set up a one-time Pre-Authorized Debit
        Agreement
      </Trans>
    ),
    consentTypeContentText: (
      <Trans>one-time Pre-Authorized Debit Agreement</Trans>
    ),
    consentTypeText: <Trans>One-time payment</Trans>,
    defaultSuccessHeading: <Trans>Pre-Authorized Debit Agreement setup</Trans>,

    toolTipContent: (
      <Trans>
        A <Text weight={FontWeight.Bold}>one-time payment</Text> will be debited
        from your account.
      </Trans>
    ),
    legalDisclaimer: ({
      textSize,
      color,
      space = 0.75,
      isConfirmationPage,
    } = {}) => (
      <XYGrid rowGap={space}>
        <Text size={textSize} color={color} weight={FontWeight.Bold}>
          <Trans>
            You have agreed to a reduction of the standard period of PAD
            agreement confirmation and will receive this notice 3 days prior to
            the debit.
          </Trans>
        </Text>
        {isConfirmationPage && (
          <Text size={textSize} weight={FontWeight.Bold}>
            <Trans>
              GoCardless may appear on your bank statement. You, the Payor,
              confirm that you have authority under the terms of your account
              agreement to authorize this debit.
            </Trans>
          </Text>
        )}
      </XYGrid>
    ),
  },
  [ConsentType.Sporadic]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully set up a sporadic Pre-Authorized Debit
        Agreement
      </Trans>
    ),
    consentTypeContentText: (
      <Trans>sporadic Pre-Authorized Debit Agreement</Trans>
    ),
    consentTypeText: <Trans>Sporadic</Trans>,
    defaultSuccessHeading: <Trans>Pre-Authorized Debit Agreement setup</Trans>,
    toolTipContent: (
      <Trans>
        Future payments will only be debited from your account following your
        pre-approval. You will be informed about how you can approve these
        transactions, which may include a phone call, online confirmation, or a
        text message.
      </Trans>
    ),
    legalDisclaimer: ({
      space = 0.75,
      textSize,
      color,
      isConfirmationPage,
    } = {}) => (
      <XYGrid rowGap={space}>
        <Text size={textSize} weight={FontWeight.Bold} color={color}>
          <Trans>
            You have agreed to a reduction of the standard period of PAD
            agreement confirmation and pre-notification for future sporadic
            PADs.
          </Trans>
        </Text>
        <Text size={textSize} weight={FontWeight.Bold}>
          <Trans>
            We will send your PAD agreement confirmation and any future notices
            of amount of each PAD 3 days before the PAD is due.
          </Trans>
        </Text>
        {isConfirmationPage && (
          <Text size={textSize} weight={FontWeight.Bold}>
            <Trans>
              GoCardless may appear on your bank statement. You, the Payor,
              confirm that you have authority under the terms of your account
              agreement to authorize this debit.
            </Trans>
          </Text>
        )}
      </XYGrid>
    ),
  },
  [ConsentType.Standing]: {
    consentTypeSuccessText: (
      <Trans>
        You&apos;ve successfully set up a standing ACH Debit Authorization
      </Trans>
    ),
    consentTypeContentText: <Trans>standing ACH Debit Authorization</Trans>,
    consentTypeText: <Trans>Standing</Trans>,
    defaultSuccessHeading: <Trans>ACH Debit Authorization setup</Trans>,
    toolTipContent: (
      <Trans>
        Future payments will only be debited from your account following your
        pre-approval. You will be informed about how you can approve these
        transactions, which may include a phone call, online confirmation, or a
        text message.
      </Trans>
    ),
  },
};

export const DisplayedConsentInformation: DisplayedConsentInformationType = {
  [Scheme.Ach]: { ...baseDisplayedConsentInformation },
  [Scheme.Pad]: {
    ...baseDisplayedConsentInformation,
    [ConsentType.Recurring]: {
      ...baseDisplayedConsentInformation.recurring,
      consentTypeSuccessText: (
        <Trans>
          You&apos;ve successfully set up a recurring Pre-Authorized Debit
          Agreement
        </Trans>
      ),
      consentTypeContentText: (
        <Trans>recurring Pre-Authorized Debit Agreement</Trans>
      ),
      defaultSuccessHeading: (
        <Trans>Pre-Authorized Debit Agreement setup</Trans>
      ),
      legalDisclaimer: ({
        space = 0.75,
        textSize,
        color,
        isConfirmationPage,
      } = {}) => (
        <XYGrid rowGap={space}>
          <Text size={textSize} color={color} weight={FontWeight.Bold}>
            <Trans>
              You have agreed to a reduction of the standard period of PAD
              agreement confirmation and pre-notification for changes in amount
              of future PADs.
            </Trans>
          </Text>
          <Text size={textSize} color={color} weight={FontWeight.Bold}>
            <Trans>
              We will send your PAD agreement confirmation and any future
              notices of amount changes of each PAD 3 days before the PAD is
              due.
            </Trans>
          </Text>
          {isConfirmationPage && (
            <Text size={textSize} weight={FontWeight.Bold}>
              <Trans>
                GoCardless may appear on your bank statement. You, the Payor,
                confirm that you have authority under the terms of your account
                agreement to authorize this debit.
              </Trans>
            </Text>
          )}
        </XYGrid>
      ),
    },
  },
};
