import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import {
  ButtonVariant,
  ColorPreset,
  P,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { getTranslatedLink, Link } from "src/common/links";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import {
  BrandedComponentType,
  getBrandColorFor,
  leadBillingRequestScheme,
} from "src/common/utils";
import { PayerThemeType } from "src/state";
import { I18n } from "@lingui/core";

import BrandedLink from "../../BrandedComponents/BrandedLink";

export const PayerTermsLink = ({
  payerTheme,
  billingRequest,
  i18n,
}: {
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
  i18n: I18n;
}) => {
  return (
    <BrandedLink
      textColor={getBrandColorFor(BrandedComponentType.FooterLink, payerTheme)}
      href={getTranslatedLink(Link.PayerTerms, i18n, billingRequest)}
      target="_blank"
      variant={ButtonVariant.Inline}
      css={{ textDecorationColor: "currentcolor" }}
    >
      Payer Terms
    </BrandedLink>
  );
};

const TermsAndConditions = ({
  billingRequest,
  payerTheme,
}: {
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
}) => {
  const { i18n } = useLingui();
  const { theme } = useTheme();
  const leadScheme = leadBillingRequestScheme(billingRequest) as Scheme;

  const privacyPolicyButton = (
    <BrandedLink
      textColor={
        getBrandColorFor(BrandedComponentType.Link, payerTheme) ||
        theme.color(ColorPreset.TextOnLight_01)
      }
      data-testid="privacyButton"
      href={getTranslatedLink(Link.Privacy, i18n)}
      target="_blank"
      variant={ButtonVariant.Inline}
      css={{ textDecoration: "underline" }}
    >
      <Trans>Privacy Notice</Trans>
    </BrandedLink>
  );

  const termsOfUseButton = (
    <BrandedLink
      textColor={
        getBrandColorFor(BrandedComponentType.Link, payerTheme) ||
        theme.color(ColorPreset.TextOnLight_01)
      }
      data-testid="payerTermsButton"
      href={getTranslatedLink(Link.PayerTerms, i18n, billingRequest)}
      target="_blank"
      variant={ButtonVariant.Inline}
      css={{ textDecoration: "underline" }}
    >
      <Trans>Payer Terms of Use</Trans>
    </BrandedLink>
  );

  return (
    <P preset={TypePreset.Caption_01} color={ColorPreset.TextOnLight_03}>
      {customiseForScheme({
        scheme: leadScheme,
        key: "billing-request.bank-connect.terms-and-conditions.v2",
        params: {
          billingRequest,
          termsOfUseButton,
          privacyPolicyButton,
        },
      })}
    </P>
  );
};

export default TermsAndConditions;
